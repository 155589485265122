import { BaseEntity, BaseSearchForm, ID } from ".";
import { DeliveryPartnerVehicle } from "./delivery_partner_vehicle";

export type DeliveryPartnerCompany = BaseEntity & {
  name?: string; //名称
  kana?: string;
  zipcode?: string;
  address?: string;
  repName?: string;
  email?: string;
  tel?: string;
  area?: string;
  memo?: string; // ,区切りの都道府県番号
  nextFollowUpDate?: string;
  followUpPeriodMonths?: number;
  deliveryPartnerVehicles?: DeliveryPartnerVehicle[];
  vehicleIds?: ID[];
};

export type DeliveryPartnerCompanyForm = DeliveryPartnerCompany;

export type DeliveryPartnerCompanySearchForm = BaseSearchForm & DeliveryPartnerCompany;
export type AssignedPartnerCompanyData = {
  [vehicleId: string]: {
    vehicleType?: string; // 傭車車種
    weight?: string; // 傭車車格
    maxWeight?: string;// 傭車最大積載量
    vehicleNumber?: string; // 傭車車番
    driverName?: string; // 傭車ドライバー名
    tel?: string; // 傭車携帯番号
  }
}

export type AssignedPartnerCompanyDataForm = {
  [datSstring: string]: AssignedPartnerCompanyData;
}

export enum AssignedPartnerCompanyDataDisplayEnum {
  車種 = "vehicleType",
  車格 = "weight",
  最大積載量 = "maxWeight",
}

export enum AssignedPartnerCompanyDataInputEnum {
  車番 = "vehicleNumber",
  ドライバー名 = "driverName",
  携帯番号 = "tel",
}
