import { Space, Row, Divider, Col } from "antd";
import { DeliveryTermView } from "components/delivery_companies/delivery_companies_view";
import { DeliveryPartnerCompany } from "entities/delivery_partner_company";
import { DeliveryPartnerVehicle } from "entities/delivery_partner_vehicle";
import { VehicleType, VehicleTypes } from "entities/vehicle";

export const getIndex = (
  deliveryPartnerCompany: DeliveryPartnerCompany,
  detailIndex: number
): number => {
  if (deliveryPartnerCompany.deliveryPartnerVehicles === undefined) return -1;
  else if (deliveryPartnerCompany.deliveryPartnerVehicles.length <= detailIndex) return -1;
  else {
    return deliveryPartnerCompany.deliveryPartnerVehicles
      .slice(0, detailIndex)
      .filter((cv) => cv.flag !== "d").length;
  }
};

export const DeliveryPartnerVehicleTypeView = (
  deliveryPartnerVehicle?: DeliveryPartnerVehicle
) => {
  if (deliveryPartnerVehicle?.vehicleType !== undefined) {
    return VehicleTypes[deliveryPartnerVehicle.vehicleType as number];
  } else {
    return "--";
  }
};

type DeliveryPartnerCompanyCardViewProps = {
  deliveryPartnerCompany?: DeliveryPartnerCompany;
  style?: React.CSSProperties;
};

export const DeliveryPartnerCompanyCardView = (
  { deliveryPartnerCompany, style }: DeliveryPartnerCompanyCardViewProps
) => {
  return (
    <Space
      size={12}
      direction="vertical"
      style={{ width: "100%", ...style }}
    >
      <Row>
        <div style={{
          fontWeight: "bold",
          fontSize: "18px",
          lineHeight: "40px",
          padding: "0px 5px",
          width: "100%"
        }}>
          {deliveryPartnerCompany?.name}
        </div>
        <div style={{
          fontSize: "16px",
          padding: "0px 5px"
        }}>
          〒{deliveryPartnerCompany?.zipcode}<br />
          {deliveryPartnerCompany?.address}
        </div>
      </Row>
      <Divider style={{ margin: 0, borderWidth: 2 }} />
      <Row justify="space-between">
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>ふりがな</Col>
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>{deliveryPartnerCompany?.kana}</Col>
      </Row>
      <Divider style={{ margin: 0, borderWidth: 2 }} />
      {/* <Row justify="space-between">
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>メールアドレス</Col>
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>{deliveryPartnerCompany?.mail}</Col>
      </Row>
      <Divider style={{ margin: 0, borderWidth: 2 }} /> */}
      <Row justify="space-between">
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>担当者氏名</Col>
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>{deliveryPartnerCompany?.repName}</Col>
      </Row>
      <Divider style={{ margin: 0, borderWidth: 2 }} />
      <Row justify="space-between">
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>電話番号</Col>
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>{deliveryPartnerCompany?.tel}</Col>
      </Row>
      <Divider style={{ margin: 0, borderWidth: 2 }} />
      <Row justify="space-between">
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>保有車両</Col>
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>{deliveryPartnerCompany?.deliveryPartnerVehicles?.map(vehicle => `${VehicleTypes[vehicle.vehicleType as VehicleType]}(${vehicle.weight}t/${vehicle.maxWeight}t,${vehicle.maxPallets}パレット,${vehicle.fare}円)`).join(",")}</Col>
      </Row>
      <Divider style={{ margin: 0, borderWidth: 2 }} />
      <Row justify="space-between">
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>備考</Col>
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>{deliveryPartnerCompany?.memo ?? "--"}</Col>
      </Row>
    </Space>
  );
};

