import { Button, Divider, Select, Space, theme } from "antd";
import { useFetchAllConsignorsApi } from "api/consignor";
import { useFetchAllDeliveryCompaniesApi } from "api/delivery_company";
import { GlobalStateContext } from "contexts/global_state_context";

import {
  Order,
  OrderDetail,
  OrderCharterType,
  OrderCharterTypes,
  OrderPointTypes,
  OrderDivided,
} from "entities/order";

import { useContext, useEffect, useState } from "react";
import { CustomButton } from "specifics/button";
import {
  CustomInputField,
  CustomInputNumberField,
  CustomSelectField,
  NumberValidation,
  StringValidation,
} from "specifics/input";
import { Form, useEffectSkipFirst } from "utils/hooks";
import { getOrderCharterTypeLabel } from "../orders/orders_view";
import { RedoOutlined, DeleteOutlined } from "@ant-design/icons";
import { OrderDetailField } from "components/orders/orders_form";
import { ID } from "entities";
import { driverOption } from "./kanban_container/container";
import { ItemData } from "./deliveries_kanban";

export const DeliveryEditView = ({
  form,
  validationResultForm,
  driverOptions,
  getItems,
  isChartered,
}: {
  form: Form<Order>;
  validationResultForm: Form<Order>;
  driverOptions: driverOption[];
  getItems: (id: string) => ItemData[];
  isChartered?: boolean;
}) => {
  const { token } = theme.useToken();
  const consignorsApi = useFetchAllConsignorsApi();
  const deliveryCompaniesApi = useFetchAllDeliveryCompaniesApi();
  const globalState = useContext(GlobalStateContext);

  useEffect(() => {
    consignorsApi.execute();
    deliveryCompaniesApi.execute();
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(
      deliveryCompaniesApi.loading || consignorsApi.loading
    );
  }, [deliveryCompaniesApi.loading || consignorsApi.loading]);

  useEffect(() => {
    validationResultForm.update((f) => {
      StringValidation(f, form, "orderNo", true);
      StringValidation(f, form, "orderDate", true);
      StringValidation(f, form, "consignorId", true);
      if (form.object.orderType === 0) {
        NumberValidation(f, form, "weight", true);
        NumberValidation(f, form, "price", true);
      }
      StringValidation(f, form, "charterType", true);
      const overweightCompanyIds: ID[] = [];
      //@ts-ignore
      // 重量分割のバリデーション
      f.orderDetails = form.object.orderDetails?.map((orderDetail) => {
        // orderDetail.deliveryCompanyIdとpointTypeに一致する orderDetails をフィルタリング
        const matchingDetails = form.object.orderDivideds?.flatMap(
          (orderDivided) =>
            orderDivided.orderDetails?.filter(
              (detail) =>
                detail.deliveryCompanyId === orderDetail.deliveryCompanyId &&
                detail.pointType === orderDetail.pointType
            )
        );

        // それらの loadWeight を合計
        const totalWeight = matchingDetails?.reduce(
          (acc, detail) => acc + (Number(detail?.loadWeight) || 0),
          0
        );
        // 重量オーバーをチェック
        const isOverweight = totalWeight! > orderDetail.loadWeight!;
        if (isOverweight) {
          overweightCompanyIds.push(orderDetail.deliveryCompanyId!);
        }
        return {
          deliveryCompanyId: [null, undefined, ""].includes(
            orderDetail.deliveryCompanyId
          )
            ? "必須項目です。"
            : undefined,
          loadWeight: isOverweight
            ? `${orderDetail.deliveryCompany?.name}の割当重量がオーバーしています。`
            : undefined,
        };
      });
      f.orderDivideds = form.object.orderDivideds?.map((divided, i) => {
        // 積みと降しで分ける
        const detailsType1 =
          divided.orderDetails?.filter((detail) => detail.pointType === 1) ||
          [];
        const detailsType2 =
          divided.orderDetails?.filter((detail) => detail.pointType === 2) ||
          [];

        // loadWeightを合計
        const totalWeightType1 = detailsType1.reduce(
          (acc, detail) => acc + (Number(detail.loadWeight) || 0),
          0
        );
        const totalWeightType2 = detailsType2.reduce(
          (acc, detail) => acc + (Number(detail.loadWeight) || 0),
          0
        );

        // 合計値を比較
        const divisionErrors: any = {};
        if (totalWeightType1 !== totalWeightType2) {
          divisionErrors.loadWeight =
            "積みと降しで合計重量が一致していません。";
        }

        return {
          orderDetails: divided.orderDetails?.map((detail, j) => {
            const detailErrors: any = {};
            detailErrors.loadWeight = divisionErrors.loadWeight ?? undefined;
            if (overweightCompanyIds.includes(detail.deliveryCompanyId!)) {
              const msg = `${detail.deliveryCompany?.name}の割当重量がオーバーしています。`;
              detailErrors.loadWeight
                ? (detailErrors.loadWeight += msg)
                : (detailErrors.loadWeight = msg);
            }
            return detailErrors;
          }),
        };
      });
    });
  }, [JSON.stringify(form.object)]);

  const loadWeight = (
    orderDetails: OrderDetail[],
    target: OrderDetail
  ): number => {
    const matchingDetail = orderDetails.find(
      (detail) =>
        detail.deliveryCompanyId === target.deliveryCompanyId &&
        detail.pointType === target.pointType
    );
    if (
      matchingDetail?.loadWeight === null ||
      matchingDetail?.loadWeight === undefined
    ) {
      return 0.0;
    } else {
      return matchingDetail?.loadWeight;
    }
  };

  const [selectedDriver, setSelectedDriver] = useState(
    driverOptions[0]?.value ?? ""
  );
  const [selectedTurn, setSelectedTurn] = useState(0);

  useEffect(() => {
    setSelectedTurn(rotationOptions(selectedDriver)[0]?.value ?? 0);
  }, [selectedDriver]);

  const rotationOptions = (vehicleId: string) => {
    const rots = getItems(vehicleId);
    const options = [];

    if (rots.length > 1) {
      for (let i = 0; i <= rots.length; i++) {
        options.push({ value: i, label: `${i + 1}回転目` });
      }
    } else if (getItems(rots[0].id).length > 0) {
      options.push({ value: rots.length, label: `${rots.length + 1}回転目` });
    } else {
      options.push({ value: 0, label: `1回転目` });
    }

    return options;
  };

  const defineTurnInRotation = () => {
    if (getItems(selectedDriver).length - 1 < selectedTurn) {
      // 新規の回転内に加える時
      return 0;
    } else {
      // 既存の回転内に加える時
      const targetRotation = getItems(selectedDriver).find(
        (rotation) => rotation.rotationTurn === selectedTurn
      );
      return getItems(targetRotation?.id ?? "").length;
    }
  };

  return (
    <Space
      direction="vertical"
      style={{
        height: "700px",
        overflowY: "scroll",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ fontSize: 16, fontWeight: "bolder", marginRight: 20 }}>
          <div>受注番号</div>
          <div style={{ fontWeight: 600 }}>{form.object.orderNo}</div>
        </div>
        <CustomSelectField
          form={form}
          validationResultForm={validationResultForm}
          label="車両"
          attr="charterType"
          selectItems={Object.keys(OrderCharterTypes)
            .map((key) => Number(key) as OrderCharterType)
            .map((orderCharterType: OrderCharterType) => ({
              label: getOrderCharterTypeLabel(orderCharterType),
              value: orderCharterType,
            }))}
          style={{ width: 123 }}
        />
      </div>
      <Space style={{ width: "100%" }} direction="vertical" size={16}>
        <CustomSelectField
          form={form}
          validationResultForm={validationResultForm}
          label="荷主"
          attr="consignorId"
          required
          selectItems={
            consignorsApi.response.data?.map((consignor) => ({
              label: consignor.name,
              value: consignor.id,
            })) || []
          }
          fieldProps={{
            showSearch: true,
            filterOption: (input, option) => {
              return (
                consignorsApi.response.data
                  ?.find((item) => item.id === option?.value)
                  ?.kana?.includes(input) ?? true
              );
            },
            optionFilterProp: "label",
          }}
          style={{ width: 770 }}
        />
        <Space style={{ width: "100%" }} direction="vertical" size={4}>
          <div
            style={{
              fontSize: 16,
              color: token.colorText,
              fontWeight: 400,
            }}
          >
            荷主がまだ登録されていない場合
          </div>
          <Space>
            <CustomButton
              onClick={() => window.open("/consignors/create?isTemporary=true")}
            >
              新規荷主を登録する
            </CustomButton>
            <Button shape="circle" onClick={() => consignorsApi.execute()}>
              <RedoOutlined />
            </Button>
          </Space>
        </Space>
      </Space>
      {form.object.orderType === 0 && (
        <div style={{ margin: "12px 0" }}>
          <CustomInputNumberField
            form={form}
            validationResultForm={validationResultForm}
            label="車格"
            attr="weight"
            required
            style={{ width: 200 }}
            fieldProps={{ addonAfter: "t", min: 0, precision: 0 }}
          />
        </div>
      )}
      <Space style={{ width: "100%" }} direction="vertical" size={16}>
        {([2, 1] as const).map((pointType) => (
          <OrderDetailField
            key={pointType}
            form={form}
            validationResultForm={validationResultForm}
            pointType={pointType}
            deliveryCompanies={deliveryCompaniesApi.response.data || []}
            style={{ width: "100%" }}
          />
        ))}
        <Space style={{ width: "100%" }} direction="vertical" size={4}>
          <div
            style={{
              fontSize: 16,
              color: token.colorText,
              fontWeight: 400,
            }}
          >
            行先(積み先・降し先)がまだ登録されていない場合
          </div>
          <Space>
            <CustomButton
              onClick={() =>
                window.open("/delivery-companies/create?isTemporary=true")
              }
            >
              新規行先を登録する
            </CustomButton>
            <Button
              shape="circle"
              onClick={() => deliveryCompaniesApi.execute()}
            >
              <RedoOutlined />
            </Button>
          </Space>
        </Space>
      </Space>
      <CustomInputField
        label="備考"
        attr="memo"
        form={form}
        style={{ width: 770 }}
        validationResultForm={validationResultForm}
      />
      <Divider />
      {(form.object.orderDivideds?.length || 0) > 0 && !isChartered && (
        <Space style={{ width: "100%" }} direction="vertical" size={4}>
          <div
            style={{
              fontSize: 16,
              color: token.colorText,
              fontWeight: 400,
            }}
          >
            配車先での重量を分割
          </div>
          <Space direction="horizontal">
            <div
              style={{
                fontSize: 16,
                color: token.colorText,
                fontWeight: 400,
              }}
            >
              分割先：
            </div>

            <Select
              value={selectedDriver}
              style={{ width: 100, margin: "7px 0" }}
              options={driverOptions}
              onSelect={(e) => setSelectedDriver(e)}
            />
            <Select
              value={selectedTurn}
              style={{ width: 100, margin: "7px 0" }}
              options={rotationOptions(selectedDriver)}
              onSelect={(e) => setSelectedTurn(e)}
            />
            <Button
              onClick={() => {
                if (form.object.orderDivideds) {
                  const newOrderDivided: OrderDivided = {
                    id: "0",
                    orderId: form.object.id,
                    rotationId:
                      getItems(selectedDriver).find(
                        (rotation) =>
                          rotation.rotationTurn === selectedTurn &&
                          getItems(rotation.id).length > 0
                      )?.id ?? "",
                    consignorId: form.object.consignorId,
                    vehicleId: selectedDriver,
                    driverName: driverOptions.find(
                      (option) => option.value === selectedDriver
                    )?.label,
                    turnInRotation: defineTurnInRotation(),
                    rotationTurn: selectedTurn,
                    loadWeight: 0,
                    charterType: form.object.charterType,
                    orderDetails: form.object.orderDetails?.map((detail) => ({
                      ...detail,
                      id: "0",
                      loadWeight: null,
                    })),
                  };
                  form.updateObject("orderDivideds", [
                    ...form.object.orderDivideds,
                    newOrderDivided,
                  ]);
                }
              }}
              style={{ marginRight: 10, fontSize: 14, fontWeight: "bolder" }}
            >
              追加
            </Button>
          </Space>
          {form.object.orderDivideds
            ?.sort((a, b) => {
              // driverNameで昇順にソート
              if (a.driverName! < b.driverName!) return -1;
              if (a.driverName! > b.driverName!) return 1;

              // driverNameが同じなら、rotationTurnで昇順にソート
              if (a.rotationTurn! < b.rotationTurn!) return -1;
              if (a.rotationTurn! > b.rotationTurn!) return 1;

              // rotationTurnが同じなら、turnInRotationで昇順にソート
              if (a.turnInRotation! < b.turnInRotation!) return -1;
              if (a.turnInRotation! > b.turnInRotation!) return 1;

              return 0;
            })
            .map((orderDivided, index1) => {
              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: 700,
                    }}
                  >
                    <div
                      style={{ fontSize: 16, fontWeight: 600 }}
                      key={orderDivided.id! + index1.toString()}
                    >
                      {orderDivided.driverName}
                      {orderDivided.rotationTurn! + 1 + "回転目：重量"}
                    </div>
                    <Button
                      shape="circle"
                      icon={<DeleteOutlined style={{ color: "#B91C1C" }} />}
                      type="dashed"
                      onClick={() => {
                        form.updateObject(
                          "orderDivideds",
                          form.object.orderDivideds?.filter(
                            (d) => d.id !== orderDivided.id
                          )
                        );
                      }}
                    />
                  </div>
                  {orderDivided.orderDetails
                    ?.sort((a, b) => {
                      // pointTypeに基づいて降順ソート
                      const pointTypeComparison = b.pointType! - a.pointType!;
                      if (pointTypeComparison !== 0) return pointTypeComparison;

                      // deliveryCompany.nameに基づいて昇順ソート
                      const nameA = a.deliveryCompany?.name || "";
                      const nameB = b.deliveryCompany?.name || "";
                      return nameA.localeCompare(nameB);
                    })
                    .map((orderDetail, index2) => (
                      <CustomInputNumberField
                        key={
                          orderDetail.id! +
                          index1.toString() +
                          index2.toString()
                        }
                        label={`${OrderPointTypes[orderDetail.pointType!]}：${orderDetail.deliveryCompany?.name
                          }`}
                        form={form}
                        validationResultForm={validationResultForm}
                        attr={[
                          "orderDivideds",
                          index1,
                          "orderDetails",
                          index2,
                          "loadWeight",
                        ]}
                        style={{ width: 700 }}
                        fieldProps={{
                          addonAfter: `kg/${loadWeight(
                            form.object.orderDetails!,
                            form.object.orderDivideds![index1].orderDetails![
                            index2
                            ]
                          )}kg`,
                          min: 0,
                          precision: 1,
                        }}
                        onChange={(e) => {
                          form.update((f) => {
                            if (
                              f.orderDivideds &&
                              f.orderDivideds[index1].orderDetails?.length
                            ) {
                              // オブジェクトのコピーを作成して更新
                              const updatedOrderDivideds = [...f.orderDivideds];
                              const updatedOrderDetails = [
                                ...updatedOrderDivideds[index1].orderDetails!,
                              ];
                              updatedOrderDetails[index2].loadWeight = e;
                              updatedOrderDivideds[index1].orderDetails =
                                updatedOrderDetails;

                              return {
                                ...f,
                                orderDivideds: updatedOrderDivideds,
                              };
                            }
                          });
                        }}
                      />
                    ))}
                  <Divider />
                </>
              );
            })}
        </Space>
      )}
    </Space>
  );
};
