import { BaseEntity, BaseSearchForm, ID } from ".";
import { Consignor } from "./consignor";
import { DeliveryCompany } from "./delivery_company";
import { User } from "./user";

export const OrderTypes = {
  0: "スポット",
  1: "常用",
};
export type OrderType = keyof typeof OrderTypes;

export const OrderCharterTypes = {
  0: "自社",
  1: "傭車",
} as const;
export type OrderCharterType = keyof typeof OrderCharterTypes;

export const OrderPointTypes = {
  1: "降し",
  2: "積み",
} as const;
export type OrderPointType = keyof typeof OrderPointTypes;

export const OrderStatus = {
  Assigned: "配車済み",
  ParticalAssigned: "一部配車済み",
  UnAssigned: "未割当",
} as const;

export const OrderUnitTypes = {
  Piece: "個",
  Book: "本",
  Sheet: "枚",
} as const;
export type OrderUnitType = keyof typeof OrderUnitTypes;

export type Order = BaseEntity & {
  orderDate?: string;
  orderType?: OrderType; // 0: スポット, 1: 常用
  consignor?: Consignor;
  consignorId?: string;

  orderNo?: string;
  status?: string;
  weight?: number;
  loadWeight?: number;
  assignedWeight?: number;
  charterType?: 0 | 1;

  registerUserId?: ID;
  registerUser?: User;
  convertUserID?: ID;
  convertUser?: User;
  isConvert?: 0 | 1;
  dispatchDate?: string;
  orderDivideds?: OrderDivided[];
  orderDetails?: OrderDetail[];
  price?: number;
  memo?: string;
};

export type OrderDivided = BaseEntity & {
  orderId?: ID;
  rotationId?: ID;
  consignorId?: ID;
  vehicleId?: ID | null;
  partnerCompanyId?: ID | null;
  charterType?: 0 | 1;
  orderDetails?: OrderDetail[];
  turnInRotation?: number;
  loadWeight?: number;
  // 配車UI用のプロパティ
  rotationTurn?: number;
  driverName?: string;
};


export type OrderDetail = BaseEntity & {
  flag?: "u" | "i" | "d";
  orderId?: ID;
  orderDividedId?: ID;
  order?: Order;
  turnInRotation?: number;
  no?: number;
  pointType?: OrderPointType; // 1:降し先、2:積み先
  deliveryDate?: string | null;
  deliveryCompany?: DeliveryCompany;
  deliveryCompanyId?: string;
  productName?: string; // 品名
  method?: string; // 工法
  quantity?: number; // 数量
  unit?: OrderUnitType; // 単位
  length?: number; // 長さ
  maxLength?: number; // 最長長さ
  loadWeight?: number | null;
  loadPallets?: number; // 使用パレット数
  arrivalTimeSpecified?: string; // 着時間指定
  memo?: string;
  isConvert?: 0 | 1;
};

export type OrderForm = Order;

export type OrderSearchForm = BaseSearchForm & Order;

export const dispatchNo = (order: OrderForm): OrderForm => {
  let checkedPointType2 = false;

  let flag = 1;
  return {
    ...order,
    orderDetails: order.orderDetails
      ?.sort((a: OrderDetail, b: OrderDetail) => {
        return (a.deliveryDate ?? "") > (b.deliveryDate ?? "") ? 1 : -1;
      })
      .map((detail) => {
        if (detail.flag === "d") {
          return detail;
        } else {
          if (checkedPointType2) {
            flag = flag + 1;
            return {
              ...detail,
              no: flag,
            };
          } else if (!checkedPointType2 && detail.pointType === 2) {
            checkedPointType2 = true;
            return {
              ...detail,
              no: 1,
            };
          } else {
            flag = flag + 1;
            return {
              ...detail,
              no: flag,
            };
          }
        }
      }) as OrderDetail[],
  };
};
