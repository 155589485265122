import {
  Button,
  Col,
  Popconfirm,
  QRCode,
  Row,
  Space,
  message,
  theme,
  Modal,
  Tooltip,
  Table,
  Typography,
  Flex,
  Descriptions,
  Input,
} from "antd";
const { Title } = Typography;
import { GlobalStateContext } from "contexts/global_state_context";
import {
  DeleteOutlined,
  PlusOutlined,
  HistoryOutlined,
  SearchOutlined
} from "@ant-design/icons";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useContext, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import {
  CustomDateField,
  CustomInputField,
  CustomSelectField,
} from "specifics/input";
import { CustomFormPageHeader } from "specifics/page_header";
import { Form, useEffectSkipFirst, useForm } from "utils/hooks";
import { CustomContentFooter } from "specifics/footer";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { DescriptionBlock } from "specifics/description";
import { Manual } from "entities/manual";
import { useFetchAllManualsApi, useFetchManualsApi } from "api/manual";
import { useFetchAllDeliveryPartnerCompaniesApi } from "api/delivery_partner_company";
import { DeliveryPartnerCompany } from "entities/delivery_partner_company";
import { ID } from "entities";
import { copyToClipboard, filterKanaName, isAllValuesValidated, queryString } from "utils/util";
import { useFetchAllVehiclesApi } from "api/vehicle";
import { Vehicle } from "entities/vehicle";
import { useGenerateTemporaryTokenApi } from "api/temporary_token";
import ReactDOM from "react-dom";
import dayjs from "dayjs";
import { debounce } from "lodash";

type DeliveryList = {
  historyIndex?: number;
  deliverers?: Deliverer[];
}

type DeliveryListHistory = {
  name?: string,
  data: DeliveryList,
  timestamp: string
};

enum DelivererType {
  運送会社 = 1,
  担当者 = 2
}

type Deliverer = {
  type?: DelivererType;
  name?: string;
  id?: string;
  deliveries?: Delivery[];
} & {
  [key in keyof typeof DelivererDetail]?: string;
}

type Delivery = {
  deliveryNo?: number;
  manualId?: ID;
}

const DelivererDetail = {
  deliveryDate: "配送日",
  vehicleType: "車種",
  vehicleNumber: "車番",
  weight: "車格",
  maxWeight: "最大積載量",
  driverName: "ドライバー名",
  tel: "携帯番号",
  loadingDetails: "積載明細",
  notes: "その他メモ",
} as const;

const validateDeliveryList = (
  form: Form<DeliveryList>,
  validationResultForm: Form<DeliveryList>
) => {
  validationResultForm.update((f) => {
    f.deliverers =
      form.object.deliverers?.map((deliverer) => {
        return {
          name:
            [undefined, null, ""].includes(deliverer.name)
              ? "必須項目です。"
              : undefined,
          deliveries: deliverer.deliveries?.map(
            (delivery) => {
              return { deliveryNo: undefined, manualId: undefined } as Delivery;
            }
          ),
        } as Deliverer;
      }) ?? [];
  });
};

export const QRPrintComponent = async (
  delivererUrls:
    {
      name: string | undefined;
      urls: ({
        url: string;
        title: string;
        order: string;
      } | null)[];
      details: { [key in keyof typeof DelivererDetail]?: string; }
    }[]) => (
  // 印刷用のコンポーネントを準備
  <div>
    {delivererUrls.map((deliverer, delivererIndex, ary) => (
      <div key={delivererIndex} style={{ pageBreakAfter: delivererIndex === ary.length - 1 ? 'initial' : 'always' }}>
        <h2 style={{ textAlign: "center" }}>{deliverer.name}</h2>
        <div>
          {Object.entries(DelivererDetail).map(([key, label]) => (
            deliverer.details?.[key as keyof typeof DelivererDetail] ?
              <div key={key} style={{ display: 'inline-block', width: key === 'loadingDetails' || key === 'notes' ? 'calc(100% - 10px)' : 'calc(25% - 10px)', margin: '5px', textAlign: 'center' }}>
                <span style={{ display: "block", fontWeight: "bolder" }}>{label}</span>
                <span>
                  {
                    key === 'deliveryDate' ?
                      dayjs(deliverer.details[key as keyof typeof DelivererDetail]).format('YYYY-MM-DD') :
                      deliverer.details[key as keyof typeof DelivererDetail]
                  }
                </span>
              </div>
              : <></>
          ))}
        </div>
        {deliverer.urls.map((data, dataIndex) => {
          if (!data?.title || !data?.url || !data?.order) {
            return null;
          }
          return (
            <div key={dataIndex} style={{ display: 'inline-block', width: 'calc(50% - 20px)', margin: "10px" }}>
              <p style={{ textAlign: "center", marginBottom: 0 }}>{data.order}</p>
              <p style={{ textAlign: "center", marginTop: 0 }}>{data.title}</p>
              <QRCode
                value={data.url}
                size={128}
                errorLevel="H"
                color="#000000"
                bgColor="#ffffff"
                type="svg"
                style={{
                  display: "block",
                  margin: "0 auto",
                  imageRendering: "pixelated",
                }}
              />
            </div>
          );
        })}
      </div>
    ))}
  </div>
);

const DeliveryListFormView = ({
  form,
  validationResultForm,
  manuals,
  deliveryPartnerCompanies,
  vehicles,
  isDriver,
  setSearchTitle,
  handleSearch
}: {
  form: Form<DeliveryList>;
  validationResultForm: Form<DeliveryList>;
  manuals: Manual[];
  deliveryPartnerCompanies: DeliveryPartnerCompany[];
  vehicles: Vehicle[];
  isDriver: boolean;
  setSearchTitle: (title: string) => void;
  handleSearch: () => void;
}) => {
  return (
    <Space
      style={{ width: "100%", borderRadius: 8 }}
      size={16}
      direction="vertical"
    >
      {form.object.deliverers
        // ?.sort((a, b) => ((a.delivererNo ?? 0) > (b.delivererNo ?? 0) ? 1 : -1))
        ?.map((deliverer, delivererIndex) => {
          return (
            <DelivererFormView
              key={`deliverer${delivererIndex}`}
              form={form}
              validationResultForm={validationResultForm}
              manuals={manuals}
              deliveryPartnerCompanies={deliveryPartnerCompanies}
              vehicles={vehicles}
              delivererIndex={delivererIndex}
              isDriver={isDriver}
              setSearchTitle={setSearchTitle}
              handleSearch={handleSearch}
            />
          );
        })
      }
      <Row>
        {
          Object.keys(DelivererType).filter((key) => isNaN(Number(key))).map(
            (type) =>
              <Col key={type} flex={1}>
                <CustomButton
                  style={{ width: "100%" }}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    form.updateObject(
                      "deliverers",
                      JSON.parse(
                        JSON.stringify([
                          ...(form.object.deliverers ?? []),
                          {
                            type: DelivererType[type as keyof typeof DelivererType],
                            deliveries: [{ deliveryNo: 1, manualId: undefined }],
                            deliveryDate: Date()
                          } as Deliverer,
                        ])
                      )
                    );
                    form.setValidate(false);
                  }}
                >
                  {type}を追加
                </CustomButton>
              </Col>
          )
        }
      </Row>
    </Space>
  );
};

const DelivererFormView = ({
  form,
  validationResultForm,
  manuals,
  deliveryPartnerCompanies,
  vehicles,
  delivererIndex,
  isDriver,
  setSearchTitle,
  handleSearch
}: {
  form: Form<DeliveryList>;
  validationResultForm: Form<DeliveryList>;
  manuals: Manual[];
  deliveryPartnerCompanies: DeliveryPartnerCompany[];
  vehicles: Vehicle[];
  delivererIndex: number;
  isDriver: boolean;
  setSearchTitle: (title: string) => void;
  handleSearch: () => void;
}) => {
  const { token } = theme.useToken();

  const onDragDeliveryEnd = (result: DropResult) => {
    // 配送先ドロップ時の処理をここに実装
    const { source, destination } = result;
    if (
      destination?.index === undefined ||
      source.index === undefined ||
      !form.object.deliverers
    ) {
      return;
    }
    const deliveries: Delivery[] = form.object.deliverers[delivererIndex].deliveries ?? [];
    const swapeddeliveries = deliveries.filter((id, index) => {
      return index !== source.index;
    });
    swapeddeliveries.splice(destination?.index, 0, deliveries[source.index]);
    form.updateObject(
      ["deliverers", delivererIndex, "deliveries"],
      swapeddeliveries.map((delivery: Delivery, no: number) => ({
        ...delivery,
        deliveryNo: no + 1,
      }))
    );
  };

  return (
    <DescriptionBlock
      label={
        <Space style={{ justifyContent: "flex-end", width: "100%" }}>
          <Popconfirm
            title={`配送者を削除します。よろしいですか？`}
            okButtonProps={{ type: "default" }}
            okText="はい"
            cancelButtonProps={{ type: "primary" }}
            cancelText="いいえ"
            onConfirm={() => {
              if ((form.getValue(["deliverers"]) as Deliverer[])?.length <= 1) {
                message.error(
                  "配送者が残り1つのときは、それ以上削除できません"
                );
              } else {
                form.update((f) => {
                  const newDeliverers = f.deliverers?.filter(
                    (_, index) => index !== delivererIndex
                  );
                  f.deliverers = JSON.parse(JSON.stringify(newDeliverers));
                });
              }
            }}
          >
            <Button
              icon={<DeleteOutlined />}
              danger
              shape="circle"
            />
          </Popconfirm>
        </Space>
      }
      labelStyle={{
        width: "100%",
        height: "auto",
      }}
      style={{
        background: token.colorBgBase,
        padding: 16,
        borderRadius: 8,
        position: "relative",
      }}
      valueStyle={{ display: "flex", flexDirection: "column", gap: 16 }}
    >
      <CustomSelectField
        required
        form={form}
        validationResultForm={validationResultForm}
        attr={["deliverers", delivererIndex, "name"]}
        label={`${DelivererType[form.getValue(["deliverers", delivererIndex, "type"]) as DelivererType]}を選択`}
        selectItems={
          form.getValue(["deliverers", delivererIndex, "type"]) === DelivererType.運送会社 ?
            deliveryPartnerCompanies.map(deliveryPartnerCompany => ({
              label: deliveryPartnerCompany.name, value: deliveryPartnerCompany.id
            })) :
            vehicles.map(vehicle => ({
              label: `${vehicle.vehicleNo}（${vehicle.user?.name}）`, value: vehicle.id
            }))
        }
        onChange={(id) => {
          switch (form.getValue(["deliverers", delivererIndex, "type"])) {
            case DelivererType.運送会社: {
              const deliveryPartnerCompany = deliveryPartnerCompanies.find(company => company.id === id);
              form.updateObject(["deliverers", delivererIndex, "name"], deliveryPartnerCompany?.name);
              form.updateObject(["deliverers", delivererIndex, "id"], deliveryPartnerCompany?.id);
              break;
            }
            case DelivererType.担当者: {
              const vehicle = vehicles.find(vehicle => vehicle.id === id);
              form.updateObject(["deliverers", delivererIndex, "name"], `${vehicle?.vehicleNo}（${vehicle?.user?.name}）`);
              form.updateObject(["deliverers", delivererIndex, "id"], vehicle?.user?.id);
              break;
            }
            default:
              console.log("error");
          }
        }}
        style={{ width: "100%" }}
        fieldProps={{
          showSearch: true,
          filterOption: filterKanaName(form.getValue(["deliverers", delivererIndex, "type"]) === DelivererType.運送会社 ?
            deliveryPartnerCompanies :
            vehicles
          ),
          optionFilterProp: "label",
        }}
        placeholder={
          form.getValue(["deliverers", delivererIndex, "type"]) === DelivererType.運送会社 ?
            "運送会社名/かなで検索" :
            "ドライバー名で検索"
        }
      />
      {!isDriver && (
        <Flex style={{ padding: "0 20px 0 20px" }} vertical>
          {Object.entries(DelivererDetail).reduce((acc, [key, label], index) => {
            if (index % 3 === 0) acc.push([]);
            acc[acc.length - 1].push({ key, label });
            return acc;
          }, [] as { key: string; label: string }[][]).map((row, rowIndex) => (
            <Row gutter={16} key={rowIndex} style={{ marginBottom: 10 }}>
              {row.map(({ key, label }) => (
                <Col span={key === 'loadingDetails' || key === 'notes' ? 24 : 8} key={key}>
                  {key === "deliveryDate" ? (
                    <CustomDateField
                      form={form}
                      validationResultForm={validationResultForm}
                      attr={["deliverers", delivererIndex, key]}
                      label={label}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <CustomInputField
                      form={form}
                      validationResultForm={validationResultForm}
                      attr={["deliverers", delivererIndex, key]}
                      label={label}
                      style={{ width: "100%" }}
                    />
                  )}
                </Col>
              ))}
            </Row>
          ))}
        </Flex>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
        }}
      >
        <DragDropContext onDragEnd={onDragDeliveryEnd}>
          <Droppable droppableId="droppable-deliveries">
            {(provided) => (
              <div style={{ display: "flex", flexDirection: "column", gap: 7 }} {...provided.droppableProps} ref={provided.innerRef}>
                {form.object.deliverers &&
                  form.object.deliverers[delivererIndex].deliveries
                    ?.sort((a, b) =>
                      (a.deliveryNo ?? 0) > (b.deliveryNo ?? 0) ? 1 : -1
                    )
                    ?.map((delivery, deliveryIndex, ary) => (
                      <Draggable
                        key={delivery.deliveryNo}
                        draggableId={String(delivery.deliveryNo)}
                        index={deliveryIndex}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div style={{ position: "relative" }}>
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  // ドラッグ中のカスタムスタイル
                                  ...(snapshot.isDragging
                                    ? {
                                      cursor: "grabbing",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      background: "lightgrey",
                                      boxShadow:
                                        "0px 0px 10px rgba(0,0,0,0.2)",
                                    }
                                    : {}),
                                }}
                              >
                                <DeliveryFormView
                                  form={form}
                                  validationResultForm={validationResultForm}
                                  manuals={manuals}
                                  delivererIndex={delivererIndex}
                                  deliveryIndex={deliveryIndex}
                                  key={`deliveryform-${delivererIndex}-${deliveryIndex}`}
                                  setSearchTitle={setSearchTitle}
                                  handleSearch={handleSearch}
                                />
                              </div>
                            </div>
                          );
                        }}
                      </Draggable>
                    ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <CustomButton
        style={{ width: "100%" }}
        onClick={() => {
          const deliveries = (form.getValue(["deliverers", delivererIndex, "deliveries"]) ??
            []) as Delivery[];
          form.updateObject(
            ["deliverers", delivererIndex, "deliveries"],
            JSON.parse(
              JSON.stringify([
                ...deliveries,
                {
                  deliveryNo:
                    Math.max(
                      ...(deliveries?.map(
                        (delivery) => delivery.deliveryNo || 0
                      ) ?? [])
                    ) + 1,
                } as Delivery,
              ])
            )
          );
        }}
        icon={<PlusOutlined />}
      >
        配送先を追加
      </CustomButton>
    </DescriptionBlock>
  );
};

const DeliveryListHistoryModal = ({ form }: { form: Form<DeliveryList> }) => {
  const { token } = theme.useToken();
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number>(0);
  const dataSource = JSON.parse(localStorage.getItem('deliveryListHistory') || '[]')
    .sort((a: { timestamp: string }, b: { timestamp: string }) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
    .map((entry: DeliveryListHistory, index: number) => ({
      key: index,
      name: entry.name,
      timestamp: entry.timestamp,
      deliverers: entry.data.deliverers,
      data: entry.data,
    }))

  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, record: any, index: number) => {
        return hoveredRowIndex === index ?
          <Input
            defaultValue={dataSource[index].name}
            disabled={hoveredRowIndex !== index}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              dataSource[index].name = e.target.value
            }}
            onMouseLeave={() => {
              localStorage.setItem('deliveryListHistory', JSON.stringify(dataSource));
            }}
          /> :
          name
      },
    },
    {
      title: '日時',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (text: string) => dayjs(text).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: '配送者',
      dataIndex: 'deliverers',
      key: 'deliverers',
      render: (deliverers: Deliverer[]) => (
        <Space>
          {deliverers.map((deliverer, i) => (
            <span key={i} style={{ borderRadius: 4, border: `1px solid ${token.colorBorder}`, padding: '2px' }}>
              {deliverer.name} - 配送数: {deliverer.deliveries?.length}
            </span>
          ))}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Tooltip title="履歴">
        <Button
          shape="circle"
          icon={<HistoryOutlined />}
          onClick={() => {
            setHistoryOpen(true);
          }}
        />
      </Tooltip>
      <Modal
        title="納入カルテ共有履歴"
        open={historyOpen}
        onCancel={() => setHistoryOpen(false)}
        footer={null}
        closable
      >
        <Table
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: 'max-content' }}
          pagination={false}
          onRow={(record, index) => ({
            style: { cursor: "pointer", color: token.colorTextSecondary },
            onClick: () => {
              form.set({
                ...record.data,
                historyIndex: index
              });
              message.success('履歴がセットされました。');
              setHistoryOpen(false);
            },
            onMouseEnter: () => setHoveredRowIndex(index || 0),
          })}
        />
      </Modal>
    </>
  );
};

const DeliveryFormView = ({
  form,
  validationResultForm,
  manuals,
  delivererIndex,
  deliveryIndex,
  setSearchTitle,
  handleSearch
}: {
  form: Form<DeliveryList>;
  validationResultForm: Form<DeliveryList>;
  manuals: Manual[];
  delivererIndex: number;
  deliveryIndex: number;
  setSearchTitle: (title: string) => void;
  handleSearch: () => void;
}) => {
  const { token } = theme.useToken();

  return (
    <DescriptionBlock
      label={
        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          <div
            style={{
              height: 24,
              fontSize: 16,
              fontWeight: 600,
              color: token.colorText,
            }}
          >
            配送順{deliveryIndex + 1}
          </div>
          <Popconfirm
            title={`配送先を削除します。よろしいですか？`}
            okButtonProps={{ type: "default" }}
            okText="はい"
            cancelButtonProps={{
              type: "primary",
            }}
            cancelText="いいえ"
            onConfirm={() => {
              if (
                (
                  form.getValue([
                    "deliverers",
                    delivererIndex,
                    "deliveries",
                  ]) as Delivery[]
                )?.length <= 1
              ) {
                message.error(
                  "配送者に紐づく配送先が残り1つのときは、それ以上削除できません"
                );
              } else {
                form.update((f) => {
                  const newdeliveries = f.deliverers![
                    delivererIndex
                  ].deliveries?.filter(
                    (_, index) =>
                      index !== deliveryIndex
                  );

                  f.deliverers![delivererIndex].deliveries =
                    JSON.parse(
                      JSON.stringify(newdeliveries)
                    );
                });
              }
            }}
          >
            <Button
              icon={<DeleteOutlined />}
              danger
              shape="circle"
            />
          </Popconfirm>
        </Space>
      }
      labelStyle={{ width: "100%" }}
      style={{
        background: token.colorBgLayout,
        padding: 16,
        borderRadius: 8,
        border: `1px solid ${token.colorBorder}`
      }}
      valueStyle={{ display: "flex", flexDirection: "column", gap: 16 }}
    >
      <CustomSelectField
        form={form}
        validationResultForm={validationResultForm}
        attr={["deliverers", delivererIndex, "deliveries", deliveryIndex, "manualId"]}
        label={`カルテを選択`}
        selectItems={manuals.map(manual => ({
          label: manual.title ?? `${manual.deliveryCompany?.name}-${manual.consignor?.name}`, value: manual.id
        }))}
        style={{ width: "100%" }}
        fieldProps={{
          showSearch: true,
          placeholder: "カルテ名で検索",
          filterOption: false,
          onSearch: setSearchTitle,
          onInputKeyDown: (e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              handleSearch();
            }
          },
          suffixIcon: (
            <SearchOutlined onClick={(e) => {
              handleSearch();
            }} />
          ),
        }}
      />
    </DescriptionBlock>
  );
};

const ManualsShareForm = (props: HistoryProps & { isDriver?: boolean }) => {
  const globalState = useContext(GlobalStateContext);
  const manualsApi = useFetchManualsApi();
  const deliveryPartnerCompaniesApi = useFetchAllDeliveryPartnerCompaniesApi();
  const generateTokenApi = useGenerateTemporaryTokenApi();
  const vehiclesApi = useFetchAllVehiclesApi();
  const form = useForm<DeliveryList>({});
  const validationResultForm = useForm<DeliveryList>({});
  const { token } = theme.useToken();
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<{ deliverers?: Deliverer & { address?: string }[], orderNo?: string }[]>([]);

  useEffect(() => {
    deliveryPartnerCompaniesApi.execute();
    vehiclesApi.execute();
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(manualsApi.loading || deliveryPartnerCompaniesApi.loading || vehiclesApi.loading);
  }, [manualsApi.loading, deliveryPartnerCompaniesApi.loading, vehiclesApi.loading]);


  useEffect(() => {
    validateDeliveryList(form, validationResultForm);
  }, [JSON.stringify(form.object)]);

  const manuals = useMemo(() => manualsApi.response.data ?? [], [manualsApi.loading]);
  const deliveryPartnerCompanies = () => deliveryPartnerCompaniesApi.response.data ?? [];
  const vehicles = () => vehiclesApi.response.data ?? [];

  const delivererUrls = async () =>
    // 各カルテに対してトークンを生成し、QRコードのURLを準備
    await Promise.all((form.object.deliverers ?? []).map(async (deliverer) => {
      // 各delivererのdeliveriesに対して非同期処理を行い、結果を配列に格納
      const deliveryUrls = await Promise.all((deliverer.deliveries ?? []).map(async (delivery) => {
        if (!delivery.manualId) {
          return null; // manualIdがない場合はnullを返してフィルタリングの対象とする
        }
        // 一時トークンを生成
        const res = await generateTokenApi.execute({ expirationOption: globalState.setting.customSetting?.qrExpiration });
        // 対応するmanualを見つける
        const item = manuals?.find((manual) => manual.id === delivery.manualId);
        // 必要な情報を形成
        const order = `配送順${delivery.deliveryNo}：`;
        const title = `${item?.title ?? `${item?.deliveryCompany?.name}-${item?.consignor?.name}`}`;
        return {
          url: `${process.env.REACT_APP_ADMIN_HOST}manuals/${item?.id}?token=${res.data.token}&delivererId=${deliverer.id}`,
          title,
          order
        };
      }));

      // nullを除外して、有効な結果のみを保持
      const filteredDeliveryUrls = deliveryUrls.filter((url) => url !== null);

      // delivererの名前と、そのdeliveriesのURLとタイトルを含むオブジェクトを返す
      return {
        name: deliverer.name,
        urls: filteredDeliveryUrls,
        details: Object.fromEntries(
          Object.keys(DelivererDetail).map((key) => [key, (deliverer as any)[key]])
        )
      };
    }));

  const groupDeliveriesByCompany = () => {
    const deliverers = form.object.deliverers ?? [];
    const groupedDeliveries: { [destinationName: string]: { deliverers?: Deliverer & { address?: string, id?: number, orderNo?: string }[], repName?: string, repTel?: string, } } = {};
    for (const deliverer of deliverers) {
      for (const delivery of deliverer.deliveries ?? []) {
        const manual = manuals.find(m => m.id === delivery.manualId);
        if (manual && manual.deliveryCompany) {
          const groupName = `${manual.deliveryPointCompany?.name || manual.deliveryCompany.name}${manual.deliveryPointCompany?.point?.memo || manual.deliveryCompany.point?.memo || ''}`;
          if (!groupedDeliveries[groupName]) {
            groupedDeliveries[groupName] = { deliverers: [] };
          }
          groupedDeliveries[groupName].deliverers?.push({ ...deliverer, address: manual.deliveryCompany.address, id: groupedDeliveries[groupName].deliverers?.length });
          groupedDeliveries[groupName] = {
            ...groupedDeliveries[groupName],
            repName: manual.deliveryPointCompany?.repName || manual.deliveryCompany.repName,
            repTel: manual.deliveryPointCompany?.repTel || manual.deliveryCompany.repTel
          }
        }
      }
    }

    return groupedDeliveries;
  };

  const ShippingConfirmationPrintComponent = () => {
    const data = groupDeliveriesByCompany();
    const cellStyle = (cell: any) => ({
      style: {
        fontSize: 12, padding: 5, width: 100
      }
    })
    const render = (text: string) => (
      <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
        {text}
      </div>
    );
    const columns = [
      {
        title: '着日',
        dataIndex: 'deliveryDate',
        key: 'deliveryDate',
        onCell: cellStyle,
        render: (date: Date) => dayjs(date).format('YYYY-MM-DD'),
      },
      {
        title: '号車',
        dataIndex: 'index',
        key: 'index',
        onCell: cellStyle,
        render: (text: any, record: any, index: number) => index + 1
      },
      {
        title: '着地',
        dataIndex: 'address',
        key: 'address',
        onCell: cellStyle,
      },
      {
        title: '車種',
        dataIndex: 'vehicleType',
        key: 'vehicleType',
        onCell: cellStyle,
      },
      {
        title: '会社名',
        dataIndex: 'name',
        key: 'name',
        onCell: cellStyle,
      },
      {
        title: '車番',
        dataIndex: 'vehicleNumber',
        key: 'vehicleNumber',
        onCell: cellStyle,
      },
      {
        title: 'ドライバー',
        dataIndex: 'driverName',
        key: 'driverName',
        onCell: cellStyle,
      },
      {
        title: '携帯番号',
        dataIndex: 'tel',
        key: 'tel',
        onCell: cellStyle,
      },
      {
        title: '積載明細',
        dataIndex: 'loadingDetails',
        key: 'loadingDetails',
        onCell: cellStyle,
        render
      },
      {
        title: 'その他メモ',
        dataIndex: 'notes',
        key: 'notes',
        onCell: cellStyle,
        render
      },
    ];
    const reorder = (list: Deliverer & { address?: string }[], startIndex: number, endIndex: number): any[] => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    interface DraggableTableProps {
      initialData: Deliverer & { address?: string }[];
      columns: any[];
      destinationIndex: number;
    }

    const DraggableTable: React.FC<DraggableTableProps> = ({ initialData, columns, destinationIndex }) => {
      if (!dataSource[destinationIndex]?.deliverers) {
        setDataSource(prev => {
          prev[destinationIndex] = { deliverers: initialData }
          return prev
        })
      }
      const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
          return;
        }
        const items = reorder(
          dataSource[destinationIndex]?.deliverers || initialData,
          result.source.index,
          result.destination.index
        );
        setDataSource(prev => {
          prev[destinationIndex].deliverers = items;
          return prev
        });
      };

      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Table
                  dataSource={dataSource[destinationIndex]?.deliverers || initialData}
                  columns={columns}
                  pagination={false}
                  rowKey="id"
                  components={{
                    body: {
                      wrapper: (props: React.HTMLProps<HTMLTableSectionElement>) => <tbody {...props} />,
                      row: ({ children, ...props }: { children: React.ReactNode, [key: string]: any }) => (
                        <Draggable draggableId={props['data-row-key']?.toString()} index={props['data-row-key']}>
                          {(provided, snapshot) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              {...props}
                              style={{
                                ...props.style,
                                ...provided.draggableProps.style,
                                background: snapshot.isDragging ? '#f4f4f4' : '',
                                boxShadow: snapshot.isDragging ? '0 2px 5px rgba(0,0,0,0.2)' : '',
                              }}
                            >
                              {children}
                            </tr>
                          )}
                        </Draggable>
                      ),
                    },
                  }}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    };

    return (
      <>
        {Object.entries(data).map(([destinationName, deliverersInfo], destinationIndex, ary) => (
          <div key={destinationName} style={{ padding: 24, pageBreakAfter: destinationIndex === ary.length - 1 ? 'initial' : 'always' }}>
            <Title level={2} style={{ textAlign: "center" }}>車番連絡票</Title>
            <Descriptions bordered column={2} size="small" style={{ marginBottom: 10 }}>
              <Descriptions.Item label="受注番号" span={1}>
                <Input
                  className="invisible-on-print"
                  value={dataSource[destinationIndex]?.orderNo}
                  onChange={(e) => {
                    const newOrderNo = e.target.value;
                    setDataSource((prev) => {
                      const newData = [...prev];
                      if (newData[destinationIndex]) {
                        newData[destinationIndex] = { ...newData[destinationIndex], orderNo: newOrderNo };
                      }
                      return newData;
                    });
                  }}
                />
                <span className="visible-on-print" style={{ display: "none" }}>{dataSource[destinationIndex]?.orderNo}</span>
              </Descriptions.Item>
              <Descriptions.Item label="行先名" span={1}>{destinationName}</Descriptions.Item>
              <Descriptions.Item label="担当者連絡先" span={2}>
                {deliverersInfo.repName && `${deliverersInfo.repName}様`}
                {deliverersInfo.repName && deliverersInfo.repTel && ' '}
                {deliverersInfo.repTel && deliverersInfo.repTel}
              </Descriptions.Item>
            </Descriptions>
            <DraggableTable initialData={deliverersInfo.deliverers || []} columns={columns} destinationIndex={destinationIndex} />
          </div>
        ))}
      </>
    )
  }

  const handleDriverBulkQRPrint = async () => {
    // 印刷用コンテンツをレンダリングするための新しいウィンドウを開く
    const printWindow = window.open('', '_blank');
    const printDocument = printWindow?.document as Document;

    // 印刷準備中のメッセージを表示
    printDocument.body.innerHTML = `<div id='print-root'><p style="text-align:center;font-size:40px">印刷準備中...</p></div>`;

    const urls = await delivererUrls();

    // Reactコンポーネントをレンダリングする前に少し待つ（必要であれば）
    await new Promise(resolve => setTimeout(resolve, 0)); // 少し待つことで、印刷準備中メッセージがユーザーに見える

    // Reactコンポーネントを新しいウィンドウのdocument内にレンダリング
    ReactDOM.render(await QRPrintComponent(urls), printDocument.getElementById('print-root'));

    setTimeout(() => {
      printWindow?.print();
      printWindow?.close();
    }, 0);
  };

  const handleAdminBulkQRPrint = async () => {
    // 印刷用のiframeを作成
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = '0';
    document.body.appendChild(iframe);

    // iframeのdocument.bodyを取得
    const docContainer = iframe.contentDocument?.body || iframe.contentWindow?.document.body as HTMLElement;
    // 印刷用のスタイルを追加
    const styleElement = document.createElement('style');
    styleElement.textContent = `
      @media print {
         @page {
          size: A4 portrait;
         }
       }
    `;
    iframe.contentDocument?.head.appendChild(styleElement);

    const urls = await delivererUrls();

    // 印刷用のコンテンツをiframeにレンダリング
    ReactDOM.render(await QRPrintComponent(urls), docContainer);

    setTimeout(() => {
      iframe.contentWindow?.print();
      document.body.removeChild(iframe);
    }, 0);
  };

  const handleAdminShippingConfirmationPrint = () => {
    // 印刷用のiframeを作成
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = '0';
    document.body.appendChild(iframe);

    // iframeのdocument.bodyを取得
    const docContainer = iframe.contentDocument?.body || iframe.contentWindow?.document.body as HTMLElement;
    // 印刷用のスタイルを追加
    const styleElement = document.createElement('style');
    styleElement.textContent = `
    @media print {
      @page {
        size: A4 landscape;
      }
      table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
      }
      .visible-on-print{
        display: inline-block !important;
      }
      .invisible-on-print{
        display: none;
      }
    }
  `;
    iframe.contentDocument?.head.appendChild(styleElement);

    // 印刷用のコンテンツをiframeにレンダリング
    ReactDOM.render(ShippingConfirmationPrintComponent(), docContainer);

    setTimeout(() => {
      iframe.contentWindow?.print();
      document.body.removeChild(iframe);
    }, 0);
  };

  const handleBulkURLCopy = async () => {
    // 各配送に対してトークンを生成し、情報を連結する非同期処理を準備
    const copyPromises = (form.object.deliverers ?? [])
      .flatMap((deliverer, delivererIndex) => (deliverer.deliveries ?? [])
        .map(async (delivery, deliveryIndex) => {
          if (!delivery.manualId) {
            return;
          }
          // 一時トークンを生成
          const res = await generateTokenApi.execute({ expirationOption: globalState.setting.customSetting?.urlExpiration }); // 有効期限1週間
          const token = encodeURIComponent(res.data.token);
          // 対応するmanualを見つける
          const item = manuals?.find((manual) => manual.id === delivery.manualId);
          // 必要な情報を形成
          return `${(delivererIndex > 0 && deliveryIndex === 0) ? `\n` : ``}${deliveryIndex === 0 ? `${deliverer.name}\n` : ``}配送順${delivery.deliveryNo}：${item?.title ?? `${item?.deliveryCompany?.name}-${item?.consignor?.name}`}\n${process.env.REACT_APP_ADMIN_HOST}manuals/${item?.id}?token=${token}&delivererId=${deliverer.id}`;
        })
      );

    // すべての非同期処理が完了するのを待つ
    const copyTexts = await Promise.all(copyPromises);

    // 結果を改行で連結してクリップボードにコピー
    const finalText = copyTexts.filter(text => !!text).join('\n');
    setTimeout(() => {
      // iphoneではsetTimeOutしないとコピーが拒否される
      copyToClipboard(finalText);
    }, 500)

    // 成功メッセージを表示
    message.success("クリップボードに共有用URLを一括コピーしました。");
  };

  const saveFormToLocalStorage = (form: Form<DeliveryList>) => {
    const history: DeliveryListHistory[] = JSON.parse(localStorage.getItem('deliveryListHistory') || '[]');

    if (typeof (form.object.historyIndex) === "number") {
      // 履歴使用時
      history[form.object.historyIndex] = { name: history[form.object.historyIndex].name, timestamp: new Date().toISOString(), data: form.object };
    } else {
      // 新規作成時
      const newEntry = { timestamp: new Date().toISOString(), data: form.object };
      history.unshift(newEntry);
      if (history.length > 10) {
        history.pop();
      }
    }

    localStorage.setItem('deliveryListHistory', JSON.stringify(history));
  };

  const [searchTitle, setSearchTitle] = useState('');
  const handleSearch = () => {
    if (searchTitle) {
      manualsApi.execute(queryString({ filter: `title:${searchTitle}` }));
    }
  };

  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="納入カルテ共有"
      childrenStyle={{ display: "flex", justifyContent: "center", paddingLeft: props.isDriver ? 0 : 32, paddingRight: props.isDriver ? 0 : 32 }}
      onBack={props.isDriver ? () => props.history.goBack() : undefined}
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
            <DeliveryListHistoryModal form={form} />
          </div>
          <DeliveryListFormView
            form={form}
            validationResultForm={validationResultForm}
            manuals={manuals}
            deliveryPartnerCompanies={deliveryPartnerCompanies()}
            vehicles={vehicles()}
            isDriver={!!props.isDriver}
            setSearchTitle={setSearchTitle}
            handleSearch={handleSearch}
          />
        </div>
        <CustomContentFooter style={{ justifyContent: props.isDriver ? "center" : "end", paddingLeft: props.isDriver ? 0 : 24, paddingRight: props.isDriver ? 0 : 24 }}>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (isAllValuesValidated(validationResultForm.object) && form.object.deliverers?.length) {
                saveFormToLocalStorage(form);
                handleBulkURLCopy();
              }
            }}
          >
            URLをコピー
          </CustomButton>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (isAllValuesValidated(validationResultForm.object) && form.object.deliverers?.length) {
                saveFormToLocalStorage(form);
                props.isDriver ?
                  handleDriverBulkQRPrint() :
                  handleAdminBulkQRPrint()
              }
            }}
          >
            QRコードを印刷
          </CustomButton>
          {!props.isDriver &&
            <CustomButton
              type="primary"
              onClick={() => {
                form.setValidate(true);
                if (isAllValuesValidated(validationResultForm.object) && form.object.deliverers?.length) {
                  saveFormToLocalStorage(form);
                  setPreviewOpen(true)
                }
              }}
            >
              車番連絡票を印刷
            </CustomButton>
          }
        </CustomContentFooter>
      </CustomShadowedContent>
      <Modal
        title="車番連絡票プレビュー"
        open={previewOpen}
        onCancel={() => {
          setPreviewOpen(false)
          setDataSource([]);
        }}
        footer={[
          <Button key="back" onClick={() => setPreviewOpen(false)}>
            閉じる
          </Button>,
          <Button key="submit" type="primary" onClick={handleAdminShippingConfirmationPrint}>
            印刷
          </Button>,
        ]}
        width={1024}
      >
        {ShippingConfirmationPrintComponent()}
      </Modal>
    </CustomFormPageHeader >
  );
};

export default withRouter(ManualsShareForm);