import { Dropdown, Space, Table, Tabs, Upload, message, theme } from "antd";
import {
  useCancelCloseOrdersApi,
  useCloseOrdersApi,
  useDeleteOrderApi,
  useDeleteOrdersApi,
  useFetchOrdersApi,
} from "api/order";
import { useFetchUsersApi } from "api/user";
import { GlobalStateContext } from "contexts/global_state_context";

import { Order, OrderTypes, OrderSearchForm, OrderType } from "entities/order";
import {
  CSSProperties,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton, UploadCSVFileButton } from "specifics/button";
import { CustomInputField } from "specifics/input";
import { CustomPageHeader } from "specifics/page_header";
import { Form, useEffectSkipFirst, useForm } from "utils/hooks";
import {
  SearchOutlined,
  UploadOutlined,
  FilterOutlined,
  SortDescendingOutlined,
  LeftOutlined,
  RightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { OrdersTable } from "./orders_table";
import { getOrderTypeLabel } from "./orders_view";
import { ID, PagingResponse } from "entities";
import dayjs from "dayjs";
import { OrderUploadCsvFileModal } from "components/orders/order_csv_import";
import { useFetchAllConsignorsApi } from "api/consignor";
import { useFetchAllDeliveryCompaniesApi } from "api/delivery_company";
import { HttpClient } from "utils/network/axios";
import {
  ApiSet,
  BaseResponse,
  IndexApiSet,
  useIndexApi,
  usePostApi,
  usePostObjectApi,
} from "utils/network/api_hooks";
import { useFetchAllDeliveryPartnerCompaniesApi } from "api/delivery_partner_company";

const OrdersPage = (props: HistoryProps) => {
  function useCountApi(): ApiSet<
    BaseResponse<{ result: { count: number; datetime: string }[] }>
  > & {
    execute: (config: { givenTimes: string[]; hasManuals?: boolean }) => void;
  } {
    const apiPath = `delivery_company/counts`;
    const api = usePostObjectApi<
      BaseResponse<{ result: { count: number; datetime: string }[] }>,
      { givenTimes: string[]; hasManuals?: boolean }
    >(new HttpClient(), {
      initialResponse: {
        data: { result: [] },
      },
    });

    const execute = (config: {
      givenTimes: string[];
      hasManuals?: boolean;
    }): void => {
      api.execute(apiPath, config);
    };

    return { ...api, execute };
  }
  const api = useCountApi();

  useEffect(() => {
    api.execute({ givenTimes: [dayjs().format()] });
  }, []);

  const globalState = useContext(GlobalStateContext);
  const listApi = useFetchOrdersApi();
  const consignorsApi = useFetchAllConsignorsApi();
  const deliveryCompaniesApi = useFetchAllDeliveryCompaniesApi();
  const partnerCompaniesApi = useFetchAllDeliveryPartnerCompaniesApi();
  const deleteApi = useDeleteOrderApi();
  const deleteOrdersApi = useDeleteOrdersApi();
  const { token } = theme.useToken();

  const closeOrdersApi = useCloseOrdersApi();
  const cancelCloseOrdersApi = useCancelCloseOrdersApi();
  const [uploadCsvOrderType, setUploadCsvOrderType] = useState<
    OrderType | undefined
  >(undefined);

  useEffect(() => {
    listApi.execute("?sorter=createdAt,desc");
    consignorsApi.execute();
    deliveryCompaniesApi.execute();
    partnerCompaniesApi.execute();
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading);
  }, [listApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(closeOrdersApi.loading);
    if (closeOrdersApi.isSuccess()) {
      props.history.push("/operations/deliveries");
    }
  }, [closeOrdersApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(cancelCloseOrdersApi.loading);
    if (cancelCloseOrdersApi.isSuccess()) {
      listApi.execute();
    }
  }, [cancelCloseOrdersApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      listApi.execute();
    }
  }, [deleteApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteOrdersApi.loading);
    if (deleteOrdersApi.isSuccess()) {
      listApi.execute();
    }
  }, [deleteOrdersApi.loading]);

  const orders = (): Order[] => {
    return listApi.response.data || [];
  };

  return (
    <CustomPageHeader
      childrenStyle={{
        backgroundColor: token.colorWhite,
        padding: 0,
      }}
      title={"受注"}
      extra={[
        <Dropdown
          key="start_csv_import"
          trigger={["click"]}
          overlayStyle={{
            boxShadow:
              "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
            borderRadius: "6px",
          }}
          menu={{
            items: [0, 1].map((category) => ({
              key: category,
              label: getOrderTypeLabel(category as OrderType),
              onClick: () => setUploadCsvOrderType(category as OrderType),
            })),
            style: {
              boxShadow:
                "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
              borderRadius: "6px",
            },
          }}
        >
          <UploadCSVFileButton />
        </Dropdown>,
        <Dropdown
          key="create_order_button"
          trigger={["click"]}
          overlayStyle={{
            boxShadow:
              "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
            borderRadius: "6px",
          }}
          menu={{
            items: [0, 1].map((category) => ({
              key: category,
              label: getOrderTypeLabel(category as OrderType),
              onClick: () =>
                props.history.push(
                  `/operations/orders/create?category=${category}`
                ),
            })),
            style: {
              boxShadow:
                "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
              borderRadius: "6px",
            },
          }}
        >
          <CustomButton key="CreateOrders" type="primary">
            受注登録
            <DownOutlined />
          </CustomButton>
        </Dropdown>,
      ]}
    >
      <div
        style={{
          backgroundColor: token.colorBgBase,
          padding: "20px 32px 32px 32px",
        }}
      >
        <OrderUploadCsvFileModal
          onComplete={() => {
            setUploadCsvOrderType(undefined);
            listApi.execute();
          }}
          consignors={consignorsApi.response.data ?? []}
          deliveryCompanies={deliveryCompaniesApi.response.data ?? []}
          partnerCompanies={partnerCompaniesApi.response.data ?? []}
          loading={consignorsApi.loading || deliveryCompaniesApi.loading}
          orderType={uploadCsvOrderType}
          open={uploadCsvOrderType !== undefined}
          onCancel={() => {
            setUploadCsvOrderType(undefined);
          }}
          title={`CSVファイルから受注登録(${
            uploadCsvOrderType !== undefined
              ? OrderTypes[uploadCsvOrderType]
              : ""
          })`}
        />
        <OrdersTable
          handleDelete={(id: ID) => deleteApi.execute(id)}
          handleBulkClose={(ids: ID[]) => closeOrdersApi.execute({ ids })}
          handleBulkCancelClose={(ids: ID[]) => {
            cancelCloseOrdersApi.execute({ ids });
          }}
          handleCancelClose={(id: ID) =>
            cancelCloseOrdersApi.execute({ ids: [id] })
          }
          handleBulkDelete={(ids: ID[]) => {
            deleteOrdersApi.execute({ ids });
          }}
          history={props.history}
          dataSource={orders()}
          listApi={listApi}
          pagination={{
            current: listApi.response.meta?.currentPage,
            pageSize: listApi.response.meta?.perPage,
            total: listApi.response.meta?.total,
          }}
        />
      </div>
    </CustomPageHeader>
  );
};

export default withRouter(OrdersPage);
